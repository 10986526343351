import * as React from "react"
import HeaderMenu from "../components/header/menu";
import FooterWrapper from "../components/footer/wrapper";
import {useSiteMetadata} from "../hooks/use-site-metadata";
import {Link} from "gatsby";
import BtnNextRed from "../components/buttons/btn-next-red";

// const {phone} = useSiteMetadata();

const page = {
  header: {
    title: "Nie znaleziono",
  }

}

// markup
const NotFoundPage = () => {
  return (
      <main>
        <title>Podziękowania</title>
        <HeaderMenu title={page.header.title} info=""/>
        <section className='pt-16 pb-12 sm:pb-48 md:pb-40 bg-white'>
            <div className='container text-center'>
                <header className='mb-6 suffix__bottom-blue--center'>
                    <h2 className='title__h2 pb-5'>{page.header.title}</h2>
                    <p className='title__prefix mb-5'>{page.header.paragraph}</p>
                </header>
                <div className="w-max mx-auto">
                    <div className='text-center sm:text-left'>

                        <Link to="/"><BtnNextRed label="Główna"></BtnNextRed></Link>

                    </div>
                </div>
            </div>
        </section>

          <FooterWrapper pages={page.footer}></FooterWrapper>
      </main>
  )
}

export default NotFoundPage
